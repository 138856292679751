import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Headline from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");
import ReactMarkdown from "react-markdown";

const ImprintPage = () => {
  const data = useStaticQuery(query);
  const { heading, imprintText } = data.strapiImpressum;
  return (
    <Layout>
      <Typography variant="h2">{heading}</Typography>
      {imprintText ? (
        <ReactMarkdown
          source={imprintText}
          escapeHtml={false}
          className={"imprint-content"}
        />
      ) : null}
    </Layout>
  );
};

const query = graphql`
  query {
    strapiImpressum {
      heading
      imprintText
      id

      # seo {
      #   metaTitle
      #   metaDescription
      #   shareImage {
      #     publicURL
      #   }
      # }
    }
  }
`;

export default ImprintPage;
